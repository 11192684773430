import classNames from "classnames"
// @ts-ignore
import { escapeRegExp } from "lodash-es"
import React, { Children, cloneElement, isValidElement, useMemo } from "react"
import { deepFind } from "../utils/deepFind"
import { WRAPPER_CLASSES, WRAPPER_MD_CLASSES } from "../utils/formComponentWrapperClasses"
import { validateDisplayNameChild } from "../utils/validateDisplayNameChild"
import { MultiSelectContextProvider, useMultiSelectContext } from "./MultiSelectContext"
import { MultiSelectControl } from "./MultiSelectControl"
import { DefaultMultiSelectInput, MultiSelectInput } from "./MultiSelectInput"
import { MultiSelectList } from "./MultiSelectList"
import { MultiSelectOption } from "./MultiSelectOption"
import type { MultiSelectProps } from "./types"
import { useMultiSelectValueTagsConfig } from "./useMultiSelectValueTagsConfig"

const MultiSelectInner = ({
  children,
  className,
  label,
  onFocus,
  onBlur,
  showSelectAllToggle = true,
}: Pick<
  MultiSelectProps,
  "children" | "className" | "label" | "onFocus" | "onBlur" | "showSelectAllToggle"
>) => {
  const {
    id,
    error,
    placeholder,
    defaultSearchInputValue: search,
    getSearchInputProps,
  } = useMultiSelectContext()
  const options = useMemo(() => {
    return Children.toArray(children)
      .filter(
        child =>
          isValidElement(child) &&
          validateDisplayNameChild(child, "MultiSelect.Option") &&
          // This search value can only be defined when the DefaultMultiSelectInput is being used.
          (search ? child.props.children.match(new RegExp(escapeRegExp(search), "i")) : true),
      )
      .map(
        (child, index) =>
          isValidElement(child) &&
          cloneElement(child, {
            ...child.props,
            index,
          }),
      )
  }, [children, search])
  const selectControlDisplayedValue = useMultiSelectValueTagsConfig({ optionEls: options })

  const customSearchInput = deepFind(
    children,
    child => isValidElement(child) && validateDisplayNameChild(child, "MultiSelect.Input"),
  )
  const _searchInput = isValidElement(customSearchInput) ? (
    cloneElement(customSearchInput, getSearchInputProps())
  ) : (
    <DefaultMultiSelectInput />
  )

  const _className = classNames(className, WRAPPER_CLASSES, WRAPPER_MD_CLASSES)

  const _label = useMemo(() => {
    return label && isValidElement(label)
      ? cloneElement(label, {
          htmlFor: label.props.htmlFor || id,
          ...label.props,
          id: `label-${id}`,
        })
      : label
  }, [label, id])

  return (
    <div className={_className}>
      {_label}
      <MultiSelectControl
        valueTagsConfig={selectControlDisplayedValue || []}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <MultiSelectList searchInput={_searchInput} showSelectAllToggle={showSelectAllToggle}>
        {options}
      </MultiSelectList>
      {!!error && <p className="text-error-base">{error}</p>}
    </div>
  )
}

/**
 * This is a form component which allows users to select multiple values in a list
 * 
 * A basic component looks like this:
 * ```tsx
 *  <MultiSelect
      label={<FormLabel label="What are your favorite marvel characters?" />}
      onChange={() => {}}
      placeholder="Select marvel heroes"
    >
    <MultiSelect.Option value="Iron Man">
      Iron Man
    </MultiSelect.Option>
    <MultiSelect.Option value="Thor">
      Thor
    </MultiSelect.Option>
    <MultiSelect.Option value="Spider-man">
      Spider-man
    </MultiSelect.Option>
  </MultiSelect>
 * ```
 *
*/
export const MultiSelect = ({
  checkboxPosition = "start",
  disabled = false,
  error,
  id,
  onChange,
  placeholder,
  value = [],
  ...rest
}: MultiSelectProps) => {
  return (
    <MultiSelectContextProvider
      value={value}
      disabled={disabled}
      id={id}
      onChange={onChange}
      error={error}
      placeholder={placeholder}
      checkboxPosition={checkboxPosition}
    >
      <MultiSelectInner {...rest} />
    </MultiSelectContextProvider>
  )
}
MultiSelect.displayName = "MultiSelect"

/**
 * An option to show in the MultiSelect list. Use as a child of MultiSelect
 *
 * The MultiSelect.Option children prop accepts any ReactNode. When the children prop is not a string, e.g. a ReactElement, you must provide a selectedTagContent prop so that the component correctly displays the tag.
 *
 * ```tsx
 * // A simple MultiSelect.Option
 * <MultiSelect.Option>Red</MultiSelect.Option>
 *
 * // A MultiSelect.Option with a ReactNode children prop
 * <MultiSelect.Option selectedTagContent="Green">
 *    <div>
 *      <span>Green</span>
 *      <span>Color</span>
 *    </div>
 * </MultiSelect.Option>
 *
 * ```
 */
MultiSelect.Option = MultiSelectOption

/**
 * A configurable input to show in the MultiSelect list. Use as a child of MultiSelect
 */
MultiSelect.Input = MultiSelectInput
