import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
} from "@floating-ui/react"
import { MouseEvent, useCallback, useRef, useState } from "react"
import type { UseSelectProps, UseSelectReturnValue } from "./types"

export function useSelect({
  disabled,
  listStyles,
  id: idProp,
  name: nameProp,
  value: valueProp,
  onChange,
}: UseSelectProps): UseSelectReturnValue {
  const id = useId()
  const listNodeRef = useRef<HTMLElement[]>([])
  const listStringRef = useRef<string[]>([])

  const groupSizeRef = useRef<number[]>([])
  const [open, setOpen] = useState(false)
  const [isTypeaheadEnabled, setTypeaheadEnabled] = useState(!disabled)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const selectedIndex = listNodeRef.current.findIndex(
    el => el?.getAttribute("data-value") === valueProp,
  )

  const floating = useFloating({
    whileElementsMounted: autoUpdate,
    placement: "bottom-start",
    strategy: "fixed",
    middleware: [
      offset(8),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: "320px",
            ...listStyles,
          })
        },
        padding: 16,
      }),
    ],
    open,
    onOpenChange: setOpen,
  })

  const interactions = useInteractions([
    useDismiss(floating.context, {
      enabled: !disabled,
    }),
    useClick(floating.context, {
      enabled: !disabled,
      toggle: true,
    }),
    useListNavigation(floating.context, {
      enabled: !disabled,
      listRef: listNodeRef,
      activeIndex,
      virtual: true,
      focusItemOnOpen: true,
      openOnArrowKeyDown: true,
      orientation: "vertical",
      onNavigate: setActiveIndex,
    }),
    useTypeahead(floating.context, {
      enabled: isTypeaheadEnabled,
      listRef: listStringRef,
      activeIndex,
      onMatch: (index: number) => {
        if (listNodeRef.current[index].getAttribute("aria-disabled") === "true") {
          return
        }
        if (open) {
          setActiveIndex(index)
        } else {
          onChange?.(listNodeRef.current[index].getAttribute("data-value"))
        }
      },
    }),
    useRole(floating.context, {
      role: "listbox",
    }),
  ])

  const onSelectValue = useCallback(
    (event?: MouseEvent<Element>) => {
      let value: string
      try {
        value = event?.currentTarget.getAttribute("data-value") ?? "" // TODO add a test which covers clicking for the value
      } catch {
        value = listNodeRef?.current[Number(activeIndex)].dataset.value as string
      }

      setOpen?.(false)
      onChange?.(value)
    },
    [listNodeRef, activeIndex, setOpen, onChange],
  )

  return {
    open,
    activeIndex,
    setActiveIndex,
    setTypeaheadEnabled,
    interactions,
    floating,
    listNodeRef,
    listStringRef,
    value: valueProp,
    setOpen,
    disabled,
    id: idProp || nameProp || id,
    selectedIndex,
    groupSizeRef,
    onSelectValue,
  }
}
