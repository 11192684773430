import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { type MRT_Row } from "material-react-table"
import { DecisionRule } from "@/types/DecisionTable"
import { Duration, DurationRangeKeys } from "../types"

dayjs.extend(duration)

// The logic here is: Sort by begin first, then by end.
// Null begin (“since always”) is less than non-null begin.
// Null end (“forever on”) is greater than non-null end.

export const sortDurationRanges = (
  rowA: MRT_Row<DecisionRule>,
  rowB: MRT_Row<DecisionRule>,
  columnId: string,
) => {
  const rangeA = rowA._valuesCache[columnId] as Duration | undefined
  const rangeB = rowB._valuesCache[columnId] as Duration | undefined

  if (!rangeA && rangeB) {
    return -1
  }

  if (rangeA && !rangeB) {
    return 1
  }

  if (!rangeA && !rangeB) {
    return 0
  }

  if (!rangeA?.[DurationRangeKeys.Begin] && rangeB?.[DurationRangeKeys.Begin]) {
    return -1
  }

  if (rangeA?.[DurationRangeKeys.Begin] && !rangeB?.[DurationRangeKeys.Begin]) {
    return 1
  }

  if (rangeA?.[DurationRangeKeys.Begin] && rangeB?.[DurationRangeKeys.Begin]) {
    const durationBeginA = dayjs.duration(rangeA?.[DurationRangeKeys.Begin]).as("hours")
    const durationBeginB = dayjs.duration(rangeB?.[DurationRangeKeys.Begin]).as("hours")

    if (durationBeginA > durationBeginB) {
      return 1
    }

    if (durationBeginB > durationBeginA) {
      return -1
    }
  }

  if (rangeA?.[DurationRangeKeys.End] && !rangeB?.[DurationRangeKeys.End]) {
    return -1
  }

  if (!rangeA?.[DurationRangeKeys.End] && rangeB?.[DurationRangeKeys.End]) {
    return 1
  }

  if (rangeA?.[DurationRangeKeys.End] && rangeB?.[DurationRangeKeys.End]) {
    const durationEndA = dayjs.duration(rangeA[DurationRangeKeys.End]).as("hours")
    const durationEndB = dayjs.duration(rangeB?.[DurationRangeKeys.End]).as("hours")

    if (durationEndA > durationEndB) {
      return 1
    }

    if (durationEndB > durationEndA) {
      return -1
    }
  }

  return 0
}
