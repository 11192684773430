import React, { useCallback, useEffect, useState } from "react"
import { FormLabel, MultiSelect, Select, useDecisionTableContext } from "@/components"
import { FieldProps } from "../types"
import { DecisionColumnEnumValue } from "@/types/DecisionTable"
import { SelectValue } from "@/components/Select"

interface ArrayFieldProps extends FieldProps {
  options: DecisionColumnEnumValue[]
  type: "simple" | "multi"
}

export const ArrayField = ({
  row,
  cell,
  column,
  accessorKey,
  label,
  options,
  type,
  disabled,
  value: defaultValue,
}: ArrayFieldProps) => {
  const [value, setValue] = useState(defaultValue || row._valuesCache[column.id])
  const {
    state: { validationErrors },
    dispatch,
  } = useDecisionTableContext()

  const handleChange = useCallback(
    (val?: SelectValue) => {
      row._valuesCache[column.id] = val
      setValue(val)
    },
    [column.id, row._valuesCache],
  )

  useEffect(() => {
    if (defaultValue) {
      handleChange(defaultValue)
    }
  }, [defaultValue, handleChange])

  return (
    <>
      <FormLabel htmlFor={cell.id} id={`label-${cell.id}`} className="mb-2" label={label} />
      {type === "simple" ? (
        <Select
          name={cell.id}
          disabled={disabled}
          onChange={handleChange}
          value={value}
          error={validationErrors?.[accessorKey]}
          onFocus={() =>
            dispatch({
              validationErrors: {
                ...validationErrors,
                [accessorKey]: undefined,
              },
            })
          }
        >
          {options.map(({ value, title }) => {
            return (
              <Select.Option key={value} value={value}>
                {title}
              </Select.Option>
            )
          })}
        </Select>
      ) : (
        <MultiSelect
          id={cell.id}
          onChange={(val?: string[]) => {
            row._valuesCache[column.id] = val
            setValue(val)
          }}
          value={value}
          error={validationErrors?.[accessorKey]}
          onFocus={() =>
            dispatch({
              validationErrors: {
                ...validationErrors,
                [accessorKey]: undefined,
              },
            })
          }
        >
          {options.map(({ title, value }) => {
            return (
              <MultiSelect.Option key={value} value={value}>
                {title}
              </MultiSelect.Option>
            )
          })}
        </MultiSelect>
      )}
    </>
  )
}
