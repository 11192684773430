import { parse, type Duration } from "tinyduration"

export const parseDurationToText = (durationString?: string) => {
  if (!durationString) {
    return null
  }

  const parseDuration = (duration: string) => {
    try {
      return duration && parse(duration)
    } catch (error) {
      if (typeof duration === "string") {
        return duration
      }
      return ""
    }
  }
  const durationParsed = parseDuration(durationString)

  const durationObjectToText = (duration: Duration) => {
    const pluralize = (number: number, text: string) => (number > 1 ? `${text}s` : text)
    const { years, months, weeks, days } = duration

    if (Object.values(duration).every(val => !val)) {
      return "0 days"
    }

    return `${years ? years + " " + pluralize(years, "year") + " " : ""}${
      months ? months + " " + pluralize(months, "month") + " " : ""
    }${weeks ? weeks + " " + pluralize(weeks, "week") + " " : ""}${
      days ? days + " " + pluralize(days, "day") + " " : ""
    }`.trim()
  }

  if (typeof durationParsed === "object") {
    return durationObjectToText(durationParsed)
  }

  return durationParsed
}
