import { parseDurationToText } from "./parseDurationToText"
import {
  DecisionColumn,
  DecisionColumnEnum,
  DecisionColumnTypeName,
  DecisionRule,
  MoneyValue,
} from "@/types/DecisionTable"
import { OWNER_COLUMN } from "../constants"
import { noCase } from "change-case"
import { type MRT_Row } from "material-react-table"
import { DurationRangeKeys } from "@/components/DecisionTableEditor/types"

const getColumnsWithOwner = (schemaColumns: DecisionColumn[]) => [...schemaColumns, OWNER_COLUMN]

const filterByCountry = (
  cellValue: string,
  filterStringParsed: string,
  schemaColumn?: DecisionColumn,
) => {
  const countryMatch = (schemaColumn?.type as DecisionColumnEnum).values.find(
    item => item.value === cellValue,
  )
  return (
    countryMatch?.title.toLowerCase().includes(filterStringParsed) ||
    countryMatch?.value.toLowerCase().includes(filterStringParsed)
  )
}

const filterBySubdivision = (
  cellValue: string,
  filterStringParsed: string,
  schemaColumn?: DecisionColumn,
) => {
  const [countryCode, subdivisionCode] = cellValue.split("-")
  const subdivisionMatch = (schemaColumn?.type as DecisionColumnEnum).values.find(item => {
    const [itemCountryCode, itemSubdivisionCode] = item.value.split("-")
    return (
      itemCountryCode.toLowerCase() === countryCode.toLowerCase() &&
      itemSubdivisionCode.toLowerCase() === subdivisionCode.toLowerCase()
    )
  })
  return (
    subdivisionMatch?.title.toLowerCase().includes(filterStringParsed) ||
    subdivisionMatch?.value.split("-")[1].toLowerCase().includes(filterStringParsed)
  )
}

interface FilterValueParams {
  columns: DecisionColumn[]
  cellValue: any
  key: string
  filterStringParsed: string
}

const filterValue = ({
  columns: schemaColumns,
  cellValue,
  key,
  filterStringParsed,
}: FilterValueParams) => {
  const matchingColumn = getColumnsWithOwner(schemaColumns).find(({ name }) => name === key)

  if (typeof cellValue !== "boolean" && !cellValue) {
    return false
  }

  if (matchingColumn?.type.name === DecisionColumnTypeName.Owner) {
    if (cellValue?.name.toLowerCase().includes(filterStringParsed)) {
      return true
    }
  }

  if (matchingColumn?.type.name === DecisionColumnTypeName.ISO31661Alpha2CountryCode) {
    const matchesCountry = filterByCountry(cellValue as string, filterStringParsed, matchingColumn)
    if (matchesCountry) {
      return true
    }
  }

  if (matchingColumn?.type.name === DecisionColumnTypeName.ISO31662SubdivisionCode) {
    const matchesSubdivision = filterBySubdivision(
      cellValue as string,
      filterStringParsed,
      matchingColumn,
    )
    if (matchesSubdivision) {
      return true
    }
  }

  if (matchingColumn?.type.name === DecisionColumnTypeName.ISO8601Duration) {
    if (
      parseDurationToText(cellValue as string)
        ?.toLowerCase()
        .includes(filterStringParsed)
    ) {
      return true
    }
  }

  if (matchingColumn?.type.name === DecisionColumnTypeName.Money) {
    const value = cellValue as MoneyValue
    if (
      value.amount.toString().includes(filterStringParsed) ||
      value.currencyCode.toLowerCase().includes(filterStringParsed)
    ) {
      return true
    }
  }

  if (Array.isArray(cellValue)) {
    let shouldBeIncluded = false
    cellValue.forEach(item => {
      if (item.toString().toLowerCase().includes(filterStringParsed)) {
        shouldBeIncluded = true
      }
    })

    if (shouldBeIncluded) {
      return true
    }
  }

  if (typeof cellValue === "object") {
    return (
      Object.entries(cellValue).filter(entry => {
        const [durationKey, durationValue] = entry

        if (Object.values(DurationRangeKeys).includes(durationKey as DurationRangeKeys)) {
          return parseDurationToText(durationValue?.toString())
            ?.toLowerCase()
            .includes(filterStringParsed)
        }
        return false
      }).length > 0
    )
  }

  const parsedCellValue = cellValue.toString().toLowerCase()

  if (
    parsedCellValue.includes(filterStringParsed) ||
    noCase(parsedCellValue).includes(filterStringParsed)
  ) {
    return true
  }

  return false
}

export const filterTable =
  (columns: DecisionColumn[]) =>
  (row: MRT_Row<DecisionRule>, _id: string, filterString: string) => {
    const filterStringParsed = filterString.toLowerCase()
    let matches = false

    // User shouldn't be filtering by row id or URLs
    Object.entries({ ...row.original, id: undefined, urls: undefined }).forEach(entry => {
      const [key, cellValue] = entry
      if (filterValue({ cellValue, filterStringParsed, key, columns })) {
        matches = true
      }
    })

    return matches
  }

export const filterColumn =
  (columns: DecisionColumn[]) => (row: MRT_Row<DecisionRule>, id: string, filterString: string) => {
    const filterStringParsed = filterString.toLowerCase()
    return filterValue({ cellValue: row.original[id], filterStringParsed, key: id, columns })
  }
