import { useState, useEffect, useRef, useCallback, SetStateAction, Dispatch } from "react"
import { devWarning } from "./devWarning"

/**
 * This hook enables a component to use its own internal state (uncontrolled) or have state passed in from a parent component (controlled). The hook will log a warning if a component changes from uncontrolled to controlled.
 * The hook is in uncontrolled mode when `controlled` is `undefined`
 * The controlled value can be of any type, so it can be used with any type of state
 */
export const useControlled = <T = unknown>({
  controlled: controlled,
  default: defaultValue,
  name,
}: {
  controlled: T
  default: T
  name: string
}): [T, Dispatch<SetStateAction<T>>] => {
  const isControlled = controlled !== undefined
  const { current: initialIsControlled } = useRef(isControlled)
  const [internalValue, setInternalValue] = useState<T>(defaultValue)
  const { current: initialInternalValue } = useRef<T>(defaultValue)
  const value = isControlled ? controlled : internalValue

  useEffect(() => {
    devWarning(
      initialIsControlled !== isControlled,
      `"${name}" is changing from ${
        initialIsControlled ? "controlled to uncontrolled" : "uncontrolled to controlled"
      }.`,
    )
  }, [initialIsControlled, isControlled, name])

  useEffect(() => {
    devWarning(
      !initialIsControlled && initialInternalValue !== defaultValue,
      `"${name}" is changing its defaultValue after being initialized. Make "${name}" a controlled component.`,
    )
  }, [initialInternalValue, defaultValue, name, initialIsControlled])

  type SetValueIfUncontrolled<T> = (newValue: SetStateAction<T>) => void

  const setValueIfUncontrolled: SetValueIfUncontrolled<T> = useCallback(
    newValue => {
      if (!initialIsControlled) {
        setInternalValue(newValue)
      }
    },
    [initialIsControlled],
  )

  return [value, setValueIfUncontrolled]
}
