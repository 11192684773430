import React, { useState } from "react"
import { Button, InfoBanner, Modal, DecisionTableEditor, useToast, Toast } from "@/components"
import { FetchMethod, doFetch } from "@/api/doFetch"
import { ErrorContent } from "../DecisionTableShow/ErrorContent"
import { useSSOTDocumentContext, useSSOTSectionsContext } from "../components"
import { DecisionTable } from "@/types/DecisionTable"
import { useIntl } from "react-intl"
import { amplitudeLogEvent } from "@/utils/amplitudeHelpers"

export const ShowSSOTDecisionTable = () => {
  const toast = useToast()
  const { $t } = useIntl()
  const {
    state: {
      ssotDocument: { subdivision, country },
      actions,
    },
  } = useSSOTDocumentContext()
  const { activeSection, updateDecisionTable } = useSSOTSectionsContext()
  const [showPublishModal, setShowPublishModal] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [error, setError] = useState(false)

  if (!activeSection.decisionTables) {
    return
  }

  const handlePublish = async () => {
    setPublishing(true)
    amplitudeLogEvent("[SE]CA-click_confirm_publish_ssot_decision_table")
    setError(false)
    await doFetch<Partial<DecisionTable>>({
      endpoint: (activeSection.decisionTables as DecisionTable[])[0].urls.api.publish,
      method: FetchMethod.POST,
      errorMessage: "Failed to publish decision table",
      body: {
        countryCode: subdivision?.code ? null : country.code,
        subdivisionCode: subdivision?.code,
      },
      done: res => {
        setPublishing(false)
        setError(false)
        setShowPublishModal(false)
        updateDecisionTable({
          id: activeSection.id,
          newDecisionTable: { ...activeSection.decisionTables?.[0], ...res } as DecisionTable,
        })
        toast({
          render: (
            <Toast variation="success">
              <Toast.Content>
                {$t({ id: "alerts.decision_table.published_successfully" })}
              </Toast.Content>
            </Toast>
          ),
        })
      },
      fail: () => {
        setPublishing(false)
        setError(true)
      },
    })
  }

  return (
    <>
      <div className="mb-12" data-testid="country-rules">
        <div className="flex justify-between items-center mb-8">
          <h3 className="text-xl font-semibold text-black">Country rules</h3>
          {actions.publish && (
            <div>
              <Button
                variant="secondary"
                onClick={() => {
                  amplitudeLogEvent("[SE]CA-click_publish_ssot_decision_table")
                  setShowPublishModal(true)
                }}
              >
                Publish
              </Button>
            </div>
          )}
        </div>
        <div className="mt-5" style={{ maxWidth: "calc(100vw - 25rem)" }}>
          <DecisionTableEditor
            actions={actions}
            decisionTable={activeSection.decisionTables[0]}
            insideSSOT={
              country
                ? {
                    countryCode: country?.code,
                    subdivisionCode: subdivision?.code,
                    editMode: false,
                    activeSection,
                    updateDecisionTable,
                  }
                : undefined
            }
          />
        </div>
      </div>
      <Modal
        isOpen={showPublishModal}
        setIsOpen={setShowPublishModal}
        title="Publish to Oyster platform"
      >
        {error ? (
          <ErrorContent />
        ) : (
          <>
            <p>
              Are you sure you want to publish these rules? Any changes will be published across all
              Oyster platform processes and immediately visible to the public.
            </p>
            <InfoBanner className="my-5">
              <p>
                Remember to publish the SSOT document to ensure all modifications are reflected on
                Zendesk.
              </p>
            </InfoBanner>
            <div className="flex justify-end">
              <div className="mr-3">
                <Button variant="secondary" onClick={() => setShowPublishModal(false)}>
                  Cancel
                </Button>
              </div>
              <div>
                <Button variant="primary" loading={publishing} onClick={handlePublish}>
                  Publish
                </Button>
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
