import React, { useEffect, useState } from "react"
import { RadioGroup, useDecisionTableContext } from "@/components"
import { FieldProps } from "../types"
import { booleanToTextValue, textValueToBoolean } from "@administrate/utils/booleanTextConverter"

export const BooleanField = ({ label, row, cell, column, accessorKey }: FieldProps) => {
  const [value, setValue] = useState(row._valuesCache[column.id])
  const {
    state: { validationErrors },
    dispatch,
  } = useDecisionTableContext()

  useEffect(() => {
    if (!row._valuesCache[column.id]) {
      setValue(false)
      row._valuesCache[column.id] = false
    }
  }, [column.id, row._valuesCache])

  return (
    <RadioGroup
      label={label}
      name={cell.id}
      onChange={e => {
        const update = textValueToBoolean((e.target as HTMLInputElement).value)
        row._valuesCache[column.id] = update
        setValue(update)
      }}
      value={booleanToTextValue(value)}
      options={[
        {
          label: "True",
          value: booleanToTextValue(true),
          defaultChecked: booleanToTextValue(value) === "true",
        },
        {
          label: "False",
          value: booleanToTextValue(false),
          defaultChecked: booleanToTextValue(value) !== "true",
        },
      ]}
      vertical={false}
      error={validationErrors?.[accessorKey]}
      onFocus={() =>
        dispatch({
          validationErrors: {
            ...validationErrors,
            [accessorKey]: undefined,
          },
        })
      }
    />
  )
}
