import React, { useState } from "react"
import { Button, Modal, Toast, useDecisionTableContext, useToast } from "@/components"
import { FetchMethod, doFetch } from "@/api/doFetch"
import { useIntl } from "react-intl"
import { amplitudeLogEvent } from "@/utils/amplitudeHelpers"
import { useExtractInfoFromSsotUrl } from "@/bundles/administrate/views/hooks/useExtractInfoFromSsotUrl"
import { DecisionTable } from "@/types/DecisionTable"

interface DeletionModalProps {
  isOpen?: string
}

export const DeletionModal = ({ isOpen }: DeletionModalProps) => {
  const toast = useToast()
  const { $t } = useIntl()
  const { dispatch, state } = useDecisionTableContext()
  const { oysterScore, section, category } = useExtractInfoFromSsotUrl()
  const [requestError, setRequestError] = useState<string>()

  const closeModal = () => dispatch({ showDeletionModal: undefined })

  const handleDeleteRule = async () => {
    setRequestError(undefined)
    dispatch({ loading: true })

    amplitudeLogEvent("[SE]-CA-click-delete-decision-rule", {
      countryCode: state.decisionRows.find(row => row.id.toString() === isOpen)?.rule.countryCode,
      category,
      section,
      oysterScore,
    })

    await doFetch({
      endpoint: `decision_rows/${isOpen}`,
      method: FetchMethod.DELETE,
      errorMessage: "Failed to delete decision rule",
      onResponse: res => {
        if (res.ok) {
          const updatedDecisionRows = state.decisionRows.filter(row => row.id.toString() !== isOpen)
          dispatch({
            decisionRows: updatedDecisionRows,
          })

          if (state.insideSSOT) {
            state.insideSSOT.updateDecisionTable({
              id: state.insideSSOT.activeSection.id,
              newDecisionTable: {
                ...state.insideSSOT.activeSection.decisionTables?.[0],
                decisionRows: updatedDecisionRows,
              } as DecisionTable,
            })
          }

          toast({
            render: (
              <Toast variation="success">
                <Toast.Content>{$t({ id: "alerts.decision_table.rule_removed" })}</Toast.Content>
              </Toast>
            ),
          })

          return closeModal()
        }
        setRequestError("There was an error. Couldn't delete decision rule")
      },
    })

    dispatch({ loading: false })
  }

  return (
    <Modal isOpen={!!isOpen} setIsOpen={closeModal} title="Confirm deletion">
      <p className="mb-4">
        This action will remove the rule from the table and across all processes within the Oyster
        platform where this information is displayed. Note that this action can&apos;t be undone.
      </p>
      <p>Are you sure you want to delete these changes?</p>
      {requestError && <p className="mt-4 text-error-base">{requestError}</p>}
      <div className="flex justify-end mt-8">
        <div className="mr-4">
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>
        <div>
          <Button variant="danger" onClick={handleDeleteRule} loading={state.loading}>
            Delete rule
          </Button>
        </div>
      </div>
    </Modal>
  )
}
