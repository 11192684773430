import React, { useState } from "react"
import { EditRuleOwnershipModal } from "./EditRuleOwnershipModal"

import { Owner, useSSOTSectionsContext } from "../../components"
import { Tooltip } from "@mui/material"
import { useIntl } from "react-intl"

interface EditRuleOwnershipProps {
  eligibleOwners: Owner[]
}

interface OwnershipButtonProps {
  disabled?: boolean
  setModalOpen: (modalOpen: boolean) => void
}

const OwnershipButton = ({ disabled, setModalOpen }: OwnershipButtonProps) => (
  <button
    onClick={() => setModalOpen(true)}
    className="text-primary-base font-medium underline"
    disabled={disabled}
  >
    Assign owner
  </button>
)

export const EditRuleOwnership = ({ eligibleOwners }: EditRuleOwnershipProps) => {
  const { activeSection, updateDecisionRuleOwner } = useSSOTSectionsContext()
  const [modalOpen, setModalOpen] = useState(false)
  const { $t } = useIntl()

  const disabled = activeSection.decisionTables?.[0]?.decisionRows?.length === 0

  return (
    <div>
      <div className="flex items-center">
        <dt className="text-gray-400">Ownership:</dt>
        <dd className="ml-2">
          {disabled ? (
            <Tooltip title={$t({ id: "edit_rule_ownership.disabled_tooltip" })}>
              <div>
                <OwnershipButton disabled={disabled} setModalOpen={setModalOpen} />
              </div>
            </Tooltip>
          ) : (
            <OwnershipButton disabled={disabled} setModalOpen={setModalOpen} />
          )}
        </dd>
      </div>

      {modalOpen && (
        <EditRuleOwnershipModal
          isOpen={modalOpen}
          closeModal={() => setModalOpen(false)}
          eligibleOwners={eligibleOwners}
          activeSection={activeSection}
          updateDecisionRuleOwner={updateDecisionRuleOwner}
        />
      )}
    </div>
  )
}
