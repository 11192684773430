import { PropsWithChildren, useLayoutEffect, useState } from "react"
// @ts-ignore
import { createPortal } from "react-dom"

export enum PortalLayer {
  Modal = "modal",
}

const PortalLayerZIndex = {
  [PortalLayer.Modal]: "0",
}

interface ReactPortalProps {
  layer: PortalLayer
  className?: string
}

function createWrapperAndAppendToBody(layer: PortalLayer, className = "") {
  const wrapperElement = document.createElement("div")
  wrapperElement.setAttribute("data-testid", `portal-${layer}`)

  if (className) {
    wrapperElement.className = className
  }

  if (PortalLayerZIndex[layer]) {
    wrapperElement.style.zIndex = PortalLayerZIndex[layer]
  }

  document.body.appendChild(wrapperElement)

  return wrapperElement
}

export const ReactPortal = ({
  children,
  layer,
  className,
}: PropsWithChildren<ReactPortalProps>) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null)

  useLayoutEffect(() => {
    const element = createWrapperAndAppendToBody(layer, className)

    setWrapperElement(element)

    return () => {
      if (element?.parentNode) {
        element.parentNode.removeChild(element)
      }
    }
  }, [className, layer])

  if (wrapperElement === null) {
    return null
  }

  return createPortal(children, wrapperElement)
}
