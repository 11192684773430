import React from "react"
import { FetchMethod, doFetch } from "@/api/doFetch"
import { Owner, OwnerSelect } from "../OwnerSelect"
import { useSSOTSectionsContext } from "../SSOTDocumentContext"
import { useIntl } from "react-intl"
import { FormLabel, Toast, useToast } from "@/components"
import { DecisionTableContentOwnership } from "@/types/DecisionTable"
import { SelectValue } from "@/components/Select"

export interface ContentOwnerProps {
  editable?: boolean
  eligibleOwners?: Owner[]
}

const noOwner: Owner = {
  id: -1,
  name: "None",
}

export const ContentOwner = ({ editable, eligibleOwners = [] }: ContentOwnerProps) => {
  const { activeSection, setActiveSection, updateSSOTSectionContentOwnership } =
    useSSOTSectionsContext()
  const { contentOwnership, id: sectionId, urls } = activeSection
  const { $t } = useIntl()
  const toast = useToast()

  const onChangeCompleted = (newContentOwnership?: DecisionTableContentOwnership) => {
    setActiveSection({ ...activeSection, contentOwnership: newContentOwnership })
    updateSSOTSectionContentOwnership({ id: activeSection.id, newContentOwnership })
    toast({
      render: (
        <Toast variation="success">
          <Toast.Content>{$t({ id: "alerts.ssot_documents.content_owner_updated" })}</Toast.Content>
        </Toast>
      ),
    })
  }

  const handleChange = async (id?: SelectValue) => {
    if (!id) {
      return
    }

    const shouldDeleteOwner = parseInt(id) === noOwner.id

    if (contentOwnership) {
      if (shouldDeleteOwner) {
        await doFetch({
          endpoint: contentOwnership?.urls.api.delete,
          method: FetchMethod.DELETE,
        })
        return onChangeCompleted(undefined)
      } else {
        await doFetch({
          endpoint: contentOwnership?.urls.api.update,
          method: FetchMethod.PATCH,
          body: {
            contentOwnership: {
              userId: id,
            },
            SSOTSectionId: sectionId,
          },
          done: (res: DecisionTableContentOwnership) => {
            onChangeCompleted(res)
          },
          fail: error =>
            toast({
              render: (
                <Toast variation="error" autohideDuration={null}>
                  <Toast.Content>{error.message}</Toast.Content>
                </Toast>
              ),
            }),
        })
        return
      }
    }

    if (!shouldDeleteOwner) {
      await doFetch({
        endpoint: urls.api.addOwner,
        method: FetchMethod.POST,
        body: {
          contentOwnership: {
            userId: id,
          },
          SSOTSectionId: sectionId,
        },
        done: (res: DecisionTableContentOwnership) => {
          onChangeCompleted(res)
        },
      })
      return
    }
  }

  return (
    <div className="flex items-center">
      <FormLabel htmlFor="content-owner" id="label-content-owner" className="mr-2" label="Owner" />
      {!editable ? (
        <p>{contentOwnership?.user.name ?? noOwner.name}</p>
      ) : (
        <div className="w-64">
          <OwnerSelect
            onChange={handleChange}
            owner={contentOwnership?.user}
            eligibleOwners={eligibleOwners}
            size="sm"
          />
        </div>
      )}
    </div>
  )
}
