// Adapted from:
//  https://github.com/KevinVandy/material-react-table/blob/v2/apps/material-react-table-docs/examples/editing-crud-modal/sandbox/src/TS.tsx
import React, { ReactNode, useCallback, useEffect, useMemo } from "react"

import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_TableInstance,
  type MRT_Row,
  type MRT_ColumnDef,
} from "material-react-table"
import { type DialogProps } from "@mui/material"

import { ThemeProvider } from "@mui/system"
import { decisionTableTheme } from "./decisionTableTheme"
import {
  EmptyDecisionTableView,
  EditRuleModalContent,
  DeletionModal,
  ActionsCell,
  ToolbarCustomActions,
  ToggleShowColumnFilters,
  PinToRight,
  Unpin,
  PinToLeft,
} from "./components"
import {
  filterTable,
  orderDecisionRowsByCountryAndSubdivision,
  validateAndParseValues,
} from "./utils"
import { REQUEST_ERROR_KEY } from "./constants"
import { DecisionTable, DecisionRule, DecisionTableName, DecisionRow } from "@/types/DecisionTable"
import { FetchMethod, doFetch } from "@/api/doFetch"
import { DecisionTableProvider, InsideSSOT, useDecisionTableContext } from "./DecisionTableContext"
import { InfoBanner, LoadingSpinner, Toast, UpdateAndPublicationInfo, useToast } from "@/components"
import { ValidationErrors } from "./types"
import { AdministrateActions, User } from "@/types/commons"
import { useGenerateColumns } from "./hooks/useGenerateColumns"
import { useIntl } from "react-intl"
import { kebabCase } from "change-case"
import { amplitudeLogEvent } from "@/utils/amplitudeHelpers"
import { useExtractInfoFromSsotUrl } from "@/bundles/administrate/views/hooks/useExtractInfoFromSsotUrl"

interface DecisionTableEditorProps {
  decisionTable: DecisionTable
  insideSSOT?: InsideSSOT
  actions: AdministrateActions
}

interface DecisionRowResponse extends DecisionRow {
  updatedBy: User
}

export const InnerTableEditor = () => {
  const { $t } = useIntl()
  const toast = useToast()
  const { oysterScore, section, category } = useExtractInfoFromSsotUrl()
  const { dispatch, state } = useDecisionTableContext()
  const { insideSSOT, decisionRows, updatedAt, updatedBy, publishedAt, publishedBy, name } = state
  const setValidationErrors = useCallback(
    (errors: ValidationErrors) => dispatch({ validationErrors: errors }),
    [dispatch],
  )

  useEffect(() => {
    if (decisionRows) {
      dispatch({ loading: false })
    }
  }, [decisionRows, dispatch])

  // This is to always leave actions as last column. This can't be done on click because
  // they get reordered afterwards
  useEffect(() => {
    if (state.pinnedColumns.right[state.pinnedColumns.right.length - 1] !== "mrt-row-actions") {
      dispatch({
        pinnedColumns: {
          left: state.pinnedColumns.left,
          right: [
            ...state.pinnedColumns.right.filter(id => id !== "mrt-row-actions"),
            "mrt-row-actions",
          ],
        },
      })
    }
  }, [dispatch, state.pinnedColumns.left, state.pinnedColumns.right])

  const tableIsEmpty = useMemo(() => decisionRows.length === 0, [decisionRows])

  // CREATE action
  const handleCreateDecisionRule = async ({
    table,
    row,
  }: {
    table: MRT_TableInstance<DecisionRule>
    row: MRT_Row<DecisionRule>
  }) => {
    dispatch({ loading: true })

    const { localValidationErrors, parsedValues } = validateAndParseValues({
      values: row._valuesCache,
      insideSSOT,
      columns: state.schema.columns,
      tableName: state.name,
    })

    amplitudeLogEvent("[SE]-CA-click-create-decision-rule", {
      countryCode: parsedValues.countryCode,
      category,
      section,
      oysterScore,
    })

    setValidationErrors(localValidationErrors)

    if (Object.values(localValidationErrors).length > 0) {
      return dispatch({ loading: false })
    }

    await doFetch({
      endpoint: `decision_tables/${state.id}/decision_rows`,
      method: FetchMethod.POST,
      errorMessage: "Failed to create decision rule",
      body: { rule: parsedValues },
      onResponse: async res => {
        if (res.ok) {
          const responseContent: DecisionRowResponse = await res.json()
          const updatedDecisionRows = [...decisionRows, responseContent]
          dispatch({
            decisionRows: updatedDecisionRows,
            updatedAt: responseContent.updatedAt,
            updatedBy: responseContent.updatedBy,
          })

          toast({
            render: (
              <Toast variation="success">
                <Toast.Content>{$t({ id: "alerts.decision_table.rule_added" })}</Toast.Content>
              </Toast>
            ),
          })

          if (state.insideSSOT) {
            state.insideSSOT.updateDecisionTable({
              id: state.insideSSOT.activeSection.id,
              newDecisionTable: {
                ...state.insideSSOT.activeSection.decisionTables?.[0],
                decisionRows: updatedDecisionRows,
                updatedAt: responseContent.updatedAt,
                updatedBy: responseContent.updatedBy,
              } as DecisionTable,
            })
          }

          return table.setCreatingRow(null)
        }

        setValidationErrors({
          [REQUEST_ERROR_KEY]: "There was an error. Couldn't create decision rule",
        })
      },
    })

    dispatch({ loading: false })
  }

  // UPDATE action
  const handleUpdateDecisionRule = async ({
    table,
    row,
  }: {
    table: MRT_TableInstance<DecisionRule>
    row: MRT_Row<DecisionRule>
  }) => {
    dispatch({ loading: true })

    const { localValidationErrors, parsedValues } = validateAndParseValues({
      values: row._valuesCache,
      insideSSOT,
      columns: state.schema.columns,
      tableName: state.name,
    })

    amplitudeLogEvent("[SE]-CA-click-update-decision-rule", {
      countryCode: parsedValues.countryCode,
      category,
      section,
      oysterScore,
    })

    setValidationErrors(localValidationErrors)

    const match = decisionRows.find(({ id }) => id === row.original.id)

    if (Object.values(localValidationErrors).length > 0 || !match) {
      return dispatch({ loading: false })
    }

    await doFetch({
      endpoint: `decision_rows/${match.id}`,
      method: FetchMethod.PATCH,
      errorMessage: "Failed to update decision rule",
      body: { rule: parsedValues },
      onResponse: async res => {
        if (res.ok) {
          const responseContent: DecisionRowResponse = await res.json()
          dispatch({
            decisionRows: [...decisionRows.filter(({ id }) => id !== match.id), responseContent],
            updatedAt: responseContent.updatedAt,
            updatedBy: responseContent.updatedBy,
          })

          toast({
            render: (
              <Toast variation="success">
                <Toast.Content>{$t({ id: "alerts.decision_table.rule_updated" })}</Toast.Content>
              </Toast>
            ),
          })

          return table.setEditingRow(null)
        }

        setValidationErrors({
          [REQUEST_ERROR_KEY]: "There was an error. Couldn't update decision rule",
        })
      },
    })

    dispatch({ loading: false })
  }

  const columns = useGenerateColumns()

  const data = useMemo(
    () =>
      orderDecisionRowsByCountryAndSubdivision(
        decisionRows.map(({ rule, id, urls, owner }) => ({ ...rule, owner, id, urls })),
      ),
    [decisionRows],
  )

  const enableEditElements = state.actions.update && (!insideSSOT || insideSSOT?.editMode)
  const shouldRenderSubdivision = useMemo(
    () => !insideSSOT || !!decisionRows.find(({ rule }) => !!rule.subdivisionCode),
    [insideSSOT, decisionRows],
  )

  const table = useMaterialReactTable({
    columns: columns as MRT_ColumnDef<DecisionRule>[],
    data,
    createDisplayMode: "modal", // default ("row", and "custom" are also available)
    editDisplayMode: "modal", // default ("row", "cell", "table", and "custom" are also available)
    enableEditing: true,
    enableStickyHeader: true,
    enableColumnPinning: true,
    filterFns: {
      myCustomFilterFn: (row: any, _id: string, filterString: string) =>
        filterTable(state.schema.columns)(row as MRT_Row<DecisionRule>, _id, filterString),
    },
    initialState: {
      sorting: [
        { id: "countryCode", desc: false },
        state.jsonSchema.properties?.subdivisionCode && { id: "subdivisionCode", desc: false },
      ].filter(elem => elem) as { id: string; desc: boolean }[],
    },
    state: {
      isLoading: state.loading,
      showLoadingOverlay: state.loading,
      showGlobalFilter: true,
      columnVisibility: {
        "mrt-row-actions": !!enableEditElements,
        countryCode: !insideSSOT,
        subdivisionCode: shouldRenderSubdivision,
      },
      showColumnFilters: state.showColumnFilters,
      columnPinning: state.pinnedColumns,
    },
    globalFilterFn: "myCustomFilterFn",
    getRowId: row => row.id?.toString(),
    muiTableContainerProps: tableIsEmpty ? { sx: { overflow: "hidden" } } : undefined,
    muiTableBodyRowProps: ({ row }) => {
      if (!row.original.id) {
        return { id: "empty" }
      }

      let id = row.original.id.toString()

      if (row.original.subdivisionCode) {
        id = row.original.subdivisionCode
      } else if (row.original.countryCode) {
        id = row.original.countryCode
      }

      return { id }
    },
    muiTableHeadCellProps: ({ column }) => ({
      id: `${kebabCase(column.id)}-header`,
      sx: {
        backgroundColor: theme => theme.palette.grey[100],
        borderTop: theme => `2px solid ${theme.palette.grey[200]}`,
        borderBottom: theme => `2px solid ${theme.palette.grey[200]}`,
        opacity: 1,
        borderTopWidth: tableIsEmpty ? 0 : undefined,
      },
    }),
    muiFilterTextFieldProps: {
      variant: "outlined",
      sx: {
        backgroundColor: theme => theme.palette.common.white,
        marginTop: 1,
      },
    },
    muiTopToolbarProps: {
      sx: {
        padding: 2,
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        border: theme => `2px solid ${theme.palette.grey[200]}`,
        borderRadius: 3,
      },
    },
    muiEditRowDialogProps: {
      PaperProps: {
        sx: {
          width: "90vw",
          maxWidth: "700px",
        },
      },
    } as DialogProps,
    defaultColumn: {
      size: 190,
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 120,
      },
    },
    autoResetPageIndex: false,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <EditRuleModalContent
        table={table}
        row={row}
        internalEditComponents={internalEditComponents}
        isNewRule
        handleSave={handleCreateDecisionRule}
      />
    ),
    // optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <EditRuleModalContent
        table={table}
        row={row}
        internalEditComponents={internalEditComponents}
        handleSave={handleUpdateDecisionRule}
      />
    ),
    renderRowActions: ({ row, table }) =>
      enableEditElements ? <ActionsCell table={table} row={row} /> : null,
    positionActionsColumn: "last",
    positionGlobalFilter: "left",
    renderToolbarInternalActions: () => {
      // options to show/hide columns, show/hide filters, toggle global filter input,
      // toggle density and toggle full screen can be added here
      return <div />
    },
    renderTopToolbarCustomActions: ({ table }) => {
      return !tableIsEmpty ? <ToolbarCustomActions table={table} /> : null
    },
    renderEmptyRowsFallback: ({ table }) => <EmptyDecisionTableView table={table} />,
    enableBottomToolbar: !tableIsEmpty,
    enableTopToolbar: !tableIsEmpty,
    renderColumnActionsMenuItems: ({ closeMenu, column }) => {
      const getPinElements = () => {
        let pinElements: ReactNode[] = []
        const isPinnedLeft = state.pinnedColumns.left.find(id => column.id === id)
        const isPinnedRight = state.pinnedColumns.right.find(id => column.id === id)

        if (isPinnedLeft) {
          pinElements = [
            <PinToRight key="pinColumnRight" closeMenu={closeMenu} column={column} />,
            <Unpin key="unpinColumn" closeMenu={closeMenu} column={column} />,
          ]
        } else if (isPinnedRight) {
          pinElements = [
            <PinToLeft key="pinColumnLeft" closeMenu={closeMenu} column={column} />,
            <Unpin key="unpinColumn" closeMenu={closeMenu} column={column} />,
          ]
        } else {
          pinElements = [
            <PinToLeft key="pinColumnLeft" closeMenu={closeMenu} column={column} />,
            <PinToRight key="pinColumnRight" closeMenu={closeMenu} column={column} />,
          ]
        }

        return pinElements
      }

      return [
        <ToggleShowColumnFilters
          key="toggleShowColumnFilters"
          column={column}
          closeMenu={closeMenu}
        />,
        ...getPinElements(),
      ]
    },
  })

  return (
    <ThemeProvider theme={decisionTableTheme}>
      {!insideSSOT?.editMode && (
        <>
          <div className="mb-5">
            <UpdateAndPublicationInfo
              updatedAt={updatedAt}
              updatedBy={updatedBy}
              publishedAt={publishedAt}
              publishedBy={publishedBy}
            />
          </div>
          {name === DecisionTableName.ProbationPeriod && (
            <InfoBanner className="mb-8">
              <div className="text-black">
                <p className="font-semibold">
                  {$t({ id: "decision_table.publishing_rules_banner.title" })}
                </p>
                <p>
                  {$t({ id: "decision_table.publishing_rules_banner.text" })}{" "}
                  <a
                    href="https://airtable.com/apps18uUegIrgiUiM/tblKj31lM63WVtw6V/viwftOwuXh05wb2d1?blocks=hide"
                    target="_blank"
                    className="underline text-success-darken-100"
                  >
                    {$t({ id: "decision_table.publishing_rules_banner.link" })}
                  </a>
                </p>
              </div>
            </InfoBanner>
          )}
        </>
      )}
      <div data-testid="decision-table">
        <MaterialReactTable table={table} />
      </div>
      <DeletionModal isOpen={state.showDeletionModal} />
    </ThemeProvider>
  )
}

const TableWrapper = () => {
  const {
    state: { schema },
  } = useDecisionTableContext()
  const isStateSet = useMemo(() => schema?.columns, [schema?.columns])
  return isStateSet ? <InnerTableEditor /> : <LoadingSpinner size="lg" />
}

export const DecisionTableEditor = ({
  decisionTable,
  insideSSOT,
  actions,
}: DecisionTableEditorProps) => {
  return (
    <DecisionTableProvider
      initialState={{
        ...decisionTable,
        insideSSOT,
        validationErrors: {},
        pinnedColumns: { left: ["countryCode"], right: ["mrt-row-actions"] },
        actions,
        loading: true,
        showColumnFilters: false,
      }}
      decisionTable={decisionTable}
    >
      <TableWrapper />
    </DecisionTableProvider>
  )
}
