import React, { useCallback, useEffect, useMemo } from "react"
import { FieldProps } from "../types"
import { parse } from "tinyduration"
import { FormLabel, useDecisionTableContext } from "@/components"
import { DurationAmountInput } from "./DurationAmountInput"
import { DurationUnitSelectInput } from "./DurationSelectInput"
import { TimeUnits } from "../../../types"
import { textUnitToIsoUnit } from "../../../utils"
import { useStateReducer } from "@/hooks/useStateReducer"
import { SelectValue } from "@/components/Select"

interface DurationFieldState {
  isoDuration: string
  amount: string
  unit: TimeUnits
}

export const DurationField = ({ cell, label, column, row, accessorKey }: FieldProps) => {
  const {
    state: { validationErrors },
    dispatch: tableStateDispatch,
  } = useDecisionTableContext()

  const getInitialAmountAndUnit = useCallback(() => {
    const defaultAmountAndUnit = { amount: "", unit: TimeUnits.Days }
    try {
      const duration = row._valuesCache[column.id]
      if (duration) {
        const parsedDuration = parse(duration)
        const setUnit = Object.entries(parsedDuration).find(entry => {
          const [_unit, amount] = entry
          return amount > 0
        })
        return setUnit ? { amount: setUnit[1], unit: setUnit[0] } : defaultAmountAndUnit
      }
      return defaultAmountAndUnit
    } catch (error) {
      return defaultAmountAndUnit
    }
  }, [row, column])
  const initialAmountAndUnit = useMemo(() => getInitialAmountAndUnit(), [getInitialAmountAndUnit])

  const { state, dispatch } = useStateReducer<DurationFieldState>({
    isoDuration: row._valuesCache[column.id] ?? "",
    amount: initialAmountAndUnit.amount,
    unit: initialAmountAndUnit.unit as TimeUnits,
  })

  useEffect(() => {
    if (!row._valuesCache[column.id]) {
      row._valuesCache = {
        ...row._valuesCache,
        [column.id]: state.isoDuration,
      }
    } else {
      row._valuesCache[column.id] = state.isoDuration
    }
  }, [column.id, row, state])

  return (
    <>
      <FormLabel htmlFor={cell.id} id={`label-${cell.id}`} className="mb-2" label={label} />
      <div className="flex">
        <DurationAmountInput
          id={cell.id}
          onChange={e => {
            const update = parseInt(e.target.value).toString()
            row._valuesCache[column.id] = update
            dispatch({
              amount: update,
              isoDuration: `P${update}${textUnitToIsoUnit(state.unit)}`,
            })
          }}
          value={state.amount}
          error={validationErrors?.[accessorKey]}
          onFocus={() =>
            tableStateDispatch({
              validationErrors: {
                ...validationErrors,
                [accessorKey]: undefined,
              },
            })
          }
        />
        <DurationUnitSelectInput
          id={cell.id}
          onChange={(val?: SelectValue) => {
            row._valuesCache[column.id] = val
            dispatch({
              unit: val as TimeUnits,
              isoDuration: `P${state.amount}${textUnitToIsoUnit(val as TimeUnits)}`,
            })
          }}
          value={state.unit}
        />
      </div>
    </>
  )
}
