import {
  CommentsFunction,
  CreateCommentsThreadFunction,
  CreateCommentsThreadRequest,
  DeleteAllCommentThreadsFunction,
  DeleteCommentFunction,
  DeleteResponse,
  EditCommentFunction,
  Fail,
  LookupCommentsFunction,
  ReplyCommentFunction,
  ResolveCommentsFunction,
  useCommentsAPI,
} from "@/api/useCommentsApi"

export type OnCommentsThreadCreated = (comment: {
  content: string
  createdAt: string
  conversationUid: string
}) => void

export type OnCommentsThreadDeleted = (data: {
  conversationUid: string
  canDelete: boolean
  reason?: string
}) => void

export interface BeforeCommentsActionParams {
  execute: () => void
  cancel: Fail
}

export interface BeforeCommentsThreadCreatedParams extends BeforeCommentsActionParams {
  req: CreateCommentsThreadRequest
}

export type BeforeCommentsThreadCreated = (params: BeforeCommentsThreadCreatedParams) => void
export type BeforeCommentsThreadDeleted = (params: BeforeCommentsActionParams) => void

export interface UseCommentsProps {
  ssotSectionId: number
  beforeCommentsThreadCreated?: BeforeCommentsThreadCreated
  beforeCommentsThreadDeleted?: BeforeCommentsThreadDeleted
  onCommentsThreadCreated?: OnCommentsThreadCreated
  onCommentsThreadDeleted?: OnCommentsThreadDeleted
}

export const useComments = ({
  ssotSectionId,
  beforeCommentsThreadCreated,
  beforeCommentsThreadDeleted,
  onCommentsThreadCreated,
  onCommentsThreadDeleted,
}: UseCommentsProps) => {
  const commentsApi = useCommentsAPI({ ssotSectionId })

  const createCommentsThread: CreateCommentsThreadFunction = ({ req, done, fail }) => {
    const execute = () =>
      commentsApi.createCommentsThread({
        req,
        done: ({ conversationUid }) => {
          done({ conversationUid })
          onCommentsThreadCreated?.({ ...req, conversationUid })
        },
        fail,
      })

    beforeCommentsThreadCreated
      ? beforeCommentsThreadCreated({ execute, cancel: fail, req })
      : execute()
  }

  const replyComment: ReplyCommentFunction = commentsApi.replyComment

  const editComment: EditCommentFunction = commentsApi.editComment

  const deleteCommentsThread: CommentsFunction<DeleteResponse, { conversationUid: string }> = ({
    req,
    done,
    fail,
  }) => {
    const execute = () =>
      commentsApi.deleteCommentsThread({
        req,
        done: ({ canDelete, reason }) => {
          done({ canDelete, reason })
          onCommentsThreadDeleted?.({
            conversationUid: req.conversationUid,
            canDelete,
            reason,
          })
        },
        fail,
      })

    beforeCommentsThreadDeleted ? beforeCommentsThreadDeleted({ execute, cancel: fail }) : execute()
  }

  const deleteAllCommentThreads: DeleteAllCommentThreadsFunction =
    commentsApi.deleteAllCommentThreads

  const deleteComment: DeleteCommentFunction = commentsApi.deleteComment

  const lookupComments: LookupCommentsFunction = commentsApi.lookupComments

  const resolveComments: ResolveCommentsFunction = commentsApi.resolveComments

  return {
    createCommentsThread,
    replyComment,
    editComment,
    deleteCommentsThread,
    deleteAllCommentThreads,
    deleteComment,
    lookupComments,
    resolveComments,
  }
}
