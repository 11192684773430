import { kebabCase } from "change-case"

export const removeStyleAndClass = (html: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, "text/html")

  const elements = doc.getElementsByTagName("*")
  for (let i = 0; i < elements.length; i++) {
    let newStyle = ""
    const element = elements[i] as HTMLElement

    const keepAllowedStyles = (allowedArray: string[], styleName: keyof CSSStyleDeclaration) => {
      const elementStyleValue = element.style[styleName]
      if (
        elementStyleValue &&
        typeof elementStyleValue === "string" &&
        allowedArray.includes(elementStyleValue)
      ) {
        newStyle += `${kebabCase(styleName as string)}: ${elementStyleValue};`
      }
    }

    const allowedColors = ["#9900ff"]
    const allowedBackgroundColors = ["#c9daf8", "#f4cccc", "#fce5cd", "#fff2cc"]
    keepAllowedStyles(allowedColors, "color")
    keepAllowedStyles(allowedBackgroundColors, "backgroundColor")

    element.style.cssText = newStyle
    element.removeAttribute("class")
  }

  return doc.body.innerHTML
}
