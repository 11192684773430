// @ts-ignore
import provinces from "provinces"
import { EMPTY_CELL } from "../constants"

interface Province {
  name: string
  country: string
  short?: string | undefined
  alt?: string[] | undefined
  region?: string | undefined
  english?: string | undefined
}

interface SubdivisionCellProps {
  countryAndSubdivision: string
}

interface GetProvince {
  countryCode: string
  subdivisionCode: string
}

export const getProvince = ({ countryCode, subdivisionCode }: GetProvince) =>
  provinces
    .filter((province: Province) => province.country === countryCode)
    .find(({ short }: Province) => short === subdivisionCode)

export const SubdivisionCell = ({ countryAndSubdivision }: SubdivisionCellProps) => {
  if (!countryAndSubdivision) {
    return EMPTY_CELL
  }
  const [countryCode, subdivisionCode] = countryAndSubdivision.split("-")
  const province = getProvince({ countryCode, subdivisionCode })
  return province?.name ?? EMPTY_CELL
}
