import {
  CreateCommentsThreadRequest,
  DeleteResponse,
  Fail,
  LookupResponse,
} from "@/api/useCommentsApi"
import { UseCommentsProps, useComments } from "./useComments"

export const useTinyCommentsCallbacks = (props: UseCommentsProps) => {
  const commentFunctions = useComments(props)

  const tinycomments_create = (
    req: CreateCommentsThreadRequest,
    done: (response: { conversationUid: string }) => void,
    fail: Fail,
  ) => {
    commentFunctions.createCommentsThread({ req, done, fail })
  }
  const tinycomments_reply = (
    req: {
      content: string
      createdAt: string
      conversationUid: string
    },
    done: (response: { commentUid: string }) => void,
    fail: Fail,
  ) => {
    commentFunctions.replyComment({ req, done, fail })
  }
  const tinycomments_edit_comment = (
    req: {
      conversationUid: string
      commentUid: string
      content: string
      modifiedAt: string
    },
    done: (response: { canEdit: boolean; reason?: string | undefined }) => void,
    fail: Fail,
  ) => {
    commentFunctions.editComment({ req, done, fail })
  }
  const tinycomments_delete = (
    req: {
      conversationUid: string
    },
    done: (response: DeleteResponse) => void,
    fail: Fail,
  ) => {
    commentFunctions.deleteCommentsThread({ req, done, fail })
  }
  const tinycomments_delete_all = (
    req: {
      conversationUid: string
    },
    done: (response: DeleteResponse) => void,
    fail: Fail,
  ) => {
    commentFunctions.deleteAllCommentThreads({ req, done, fail })
  }
  const tinycomments_delete_comment = (
    req: {
      conversationUid: string
      commentUid: string
    },
    done: (response: DeleteResponse) => void,
    fail: Fail,
  ) => {
    commentFunctions.deleteComment({ req, done, fail })
  }
  const tinycomments_lookup = (
    req: {
      conversationUid: string
    },
    done: (response: LookupResponse) => void,
    fail: Fail,
  ) => {
    commentFunctions.lookupComments({ req, done, fail })
  }
  const tinycomments_resolve = (
    req: {
      conversationUid: string
    },
    done: (response: { canResolve: boolean; reason?: string | undefined }) => void,
    fail: Fail,
  ) => {
    commentFunctions.resolveComments({ req, done, fail })
  }

  return {
    tinycomments_create,
    tinycomments_reply,
    tinycomments_edit_comment,
    tinycomments_delete,
    tinycomments_delete_all,
    tinycomments_delete_comment,
    tinycomments_lookup,
    tinycomments_resolve,
  }
}
