import { camelCase } from "change-case"
import { DecisionColumn, DecisionRuleInput, DecisionTableName } from "@/types/DecisionTable"
import { InsideSSOT } from "../DecisionTableContext"
import { ValidationErrors } from "@/components/DecisionTableEditor/types"

interface ValidateValueParams {
  value: any
  title: string
  required: boolean
}

const validateValue = ({ value, title, required }: ValidateValueParams) => {
  let validationError = undefined

  if (typeof value === "string" && value.length > 250) {
    validationError = `${title} has a limit of 250 characters`
  }
  if (required && typeof value === "number" && isNaN(value)) {
    return (validationError = `${title} required`)
  }
  if (required && typeof value !== "boolean" && (!value || value.length === 0)) {
    return (validationError = `${title} required`)
  }

  return validationError
}

type Value = string | number | boolean | Array<string> | Record<string, string> | undefined

const parseValue = (value: Value): Value => {
  let parsedValue = value
  if (value === "") {
    return (parsedValue = undefined)
  } else if (typeof value === "object") {
    if (Array.isArray(value)) {
      return (parsedValue = value)
    }
    const truthyValues = Object.values(value).filter(val => val)
    if (truthyValues.length === 0) {
      return (parsedValue = undefined)
    }
    parsedValue = Object.entries(value).reduce((acc, entry) => {
      const [key, val] = entry

      return { ...acc, [key]: parseValue(val) }
    }, {})
    return parsedValue
  }

  return parsedValue
}

export const validateAndParseValues = ({
  values,
  insideSSOT,
  columns,
  tableName,
}: {
  values: DecisionRuleInput
  insideSSOT?: InsideSSOT
  columns: DecisionColumn[]
  tableName: string
}) => {
  const localValidationErrors: ValidationErrors = {}
  const parsedValues: Record<string, any> = {}

  Object.entries(values).forEach(entry => {
    const [key, value] = entry
    const { required, title } = columns.find(({ name }) => camelCase(name) === key) || {}

    let validationError = undefined

    // Inside the SSOT page the country field isn't shown as it can't be edited
    if ((!insideSSOT || key !== "countryCode") && title) {
      validationError = validateValue({ value, title, required: !!required })
    }

    if (validationError) {
      localValidationErrors[key] = validationError
    }

    if (key.toLowerCase() === "owner") {
      return (parsedValues[key] = undefined)
    }

    if (tableName === DecisionTableName.ProbationPeriod) {
      if (!values.extendable && key.toLowerCase().includes("extension")) {
        return (parsedValues[key] = undefined)
      }
    }

    parsedValues[key] = parseValue(value)
  })

  if (insideSSOT) {
    parsedValues.countryCode = parsedValues.countryCode ?? insideSSOT.countryCode
    parsedValues.subdivisionCode = parsedValues.subdivisionCode ?? insideSSOT.subdivisionCode
  }

  return { localValidationErrors, parsedValues }
}
