import React from "react"
import { parseDurationToText } from "../../../utils"
import { sentenceCase } from "change-case"

interface StringCellProps {
  value: string | number
}

export const StringCell = ({ value }: StringCellProps) => {
  return value ? <span>{sentenceCase(parseDurationToText(value.toString()) as string)}</span> : null
}
