import { FormLabel, Select } from "@/components"
import { SelectValue } from "@/components/Select"
import React, { useMemo, useState } from "react"

export interface Owner {
  id: number
  name: string
}

interface OwnerSelectProps {
  onChange: (value?: SelectValue) => void
  owner?: Owner
  previousOwner?: Owner
  eligibleOwners: Owner[]
  error?: string
  size?: "sm" | "md"
  label?: string
}

export const NO_OWNER: Owner = {
  id: -1,
  name: "None",
}

export const OwnerSelect = ({
  onChange,
  owner,
  eligibleOwners,
  error,
  size = "md",
  label,
  previousOwner,
}: OwnerSelectProps) => {
  const [filter, setFilter] = useState<string>("")
  const filteredOwners = useMemo(
    () => eligibleOwners.filter(({ name }) => name.toLowerCase().includes(filter.toLowerCase())),
    [eligibleOwners, filter],
  )

  const getEndAdornment = (userId: number) => {
    const endAdornment = <span className="text-xs">Owner</span>

    if (previousOwner && userId === previousOwner?.id) {
      return endAdornment
    }

    if (!previousOwner && userId === owner?.id) {
      return endAdornment
    }

    return null
  }

  return (
    <Select
      name="content-owner"
      onChange={onChange}
      label={label ? <FormLabel label={label} /> : null}
      value={(owner?.id ?? NO_OWNER.id).toString()}
      size={size}
      UNSAFE_selectedValueDisplay={() => (
        <span data-testid="owner">{owner?.name ?? NO_OWNER.name}</span>
      )}
      error={error}
      onFocus={() => setFilter("")}
    >
      <Select.Input
        placeholder="Search by name"
        onChange={e => {
          setFilter(e.target.value)
        }}
        value={filter}
      />
      <Select.Group>
        <Select.Option value={NO_OWNER.id.toString()}>{NO_OWNER.name}</Select.Option>
      </Select.Group>
      <Select.Group>
        {filteredOwners.map(({ id, name }) => {
          return (
            <Select.Option key={id} value={id.toString()} endAdornment={getEndAdornment(id)}>
              {name}
            </Select.Option>
          )
        })}
      </Select.Group>
    </Select>
  )
}
