export enum FetchMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
  PATCH = "PATCH",
}

export type Done<T = Record<string, any>> = (responseField: T) => void

interface DoFetchParams<T> {
  endpoint: string
  method: FetchMethod
  onResponse?: (response: Response) => void
  body?: Record<string, any>
  errorMessage?: string
  parseResponseField?: string | null
  done?: Done<T>
  fail?: (e: Error) => void
}

const urlify = (endpoint: string) =>
  endpoint.startsWith("http") ? endpoint : `/api/v1/${endpoint}`

export const doFetch = async <T>({
  endpoint,
  method,
  onResponse,
  body,
  errorMessage,
  parseResponseField,
  done,
  fail,
}: DoFetchParams<T>) => {
  try {
    const response = await fetch(urlify(endpoint), {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    if (onResponse) {
      return onResponse(response)
    }

    if (!response.ok) {
      // TODO: Don't throw JSON as error message
      const json = await response.json()
      const stringified = json ? JSON.stringify(json) : null
      throw new Error(errorMessage ?? stringified ?? "Something went wrong")
    }

    const jsonResponse = await response.json()

    if (done) {
      done(
        parseResponseField
          ? { [parseResponseField]: jsonResponse[parseResponseField] }
          : jsonResponse,
      )
    }

    return jsonResponse
  } catch (e) {
    if (fail) {
      if (e instanceof Error) {
        fail(e)
      } else {
        fail(new Error(String(e)))
      }
    }
  }
}
