import React, { useState } from "react"
import { Button, InfoBanner, Modal, Toast, useToast } from "@/components"
import { useSSOTSectionAPI } from "@/api/useSSOTSectionAPI"
import { SSOTSection } from "@/types/SSOTDocument"
import { useIntl } from "react-intl"
import { APIError, APIErrorType } from "./types"
import { amplitudeLogEvent } from "@/utils/amplitudeHelpers"
import { isPublicOysterScore } from "@/components/utils/isPublicOysterScore"
import { OysterScores } from "@/types/DecisionTable"

export interface ConfirmPublishModalProps {
  modalOpen: boolean
  onClose: () => void
  activeSection: SSOTSection
  setActiveSection: (activeSection: SSOTSection) => void
}

export const ConfirmPublishModal = ({
  modalOpen,
  onClose,
  setActiveSection,
  activeSection,
}: ConfirmPublishModalProps) => {
  const [publishing, setPublishing] = useState(false)
  const { publishSSOTSection } = useSSOTSectionAPI()
  const { $t } = useIntl()
  const toast = useToast()

  const confirmModalTranslation =
    !activeSection.publishedAt && isPublicOysterScore(activeSection.kind as OysterScores)
      ? `administrate.actions.ssot_documents.confirm_publish_with_siblings_${activeSection.kind}`
      : "administrate.actions.ssot_documents.confirm_publish"

  const handlePublish = async () => {
    setPublishing(true)
    amplitudeLogEvent("[SE]CA-click_confirm_publish_ssot_document_to_zendesk")

    await publishSSOTSection({
      id: activeSection.id,
      done: (res: Partial<SSOTSection>) => {
        setActiveSection({ ...activeSection, ...res })
        toast({
          render: (
            <Toast variation="success">
              <Toast.Content>{$t({ id: "alerts.ssot_documents.publish_success" })}</Toast.Content>
            </Toast>
          ),
        })
        setPublishing(false)
        onClose()
      },
      fail: async error => {
        const { errors } = JSON.parse(error.message) as { errors: APIError[] }
        const typesWithSupportLink: APIErrorType[] = [
          "api_incompatibility",
          "content_format_issue",
          "authentication_failure",
          "connection_failure",
          "article_sync_error",
          "zendesk_issue",
        ]

        if (errors.length > 0) {
          errors.forEach(({ type, title, message }) => {
            toast({
              render: (
                <Toast variation="error" autohideDuration={null}>
                  <Toast.Title>{title}</Toast.Title>
                  <Toast.Content>
                    <div>{message}</div>
                    {typesWithSupportLink.includes(type) && (
                      <div className="my-2 text-primary-base underline">
                        <a href="https://form.asana.com/?k=4OQoBG0QnJNsr8rM5Tkx9w&d=1198919668225239&embed=true">
                          {$t({ id: "alerts.ssot_documents.contact_support" })}
                        </a>
                      </div>
                    )}
                  </Toast.Content>
                </Toast>
              ),
            })
          })
        }
        setPublishing(false)
        onClose()
      },
    })
  }

  return (
    <Modal title="Confirm publish" isOpen={modalOpen} setIsOpen={onClose}>
      <div className="max-w-lg">
        <p className="mb-5">{$t({ id: confirmModalTranslation })}</p>

        <InfoBanner>
          <p>{$t({ id: "administrate.actions.ssot_documents.decision_table_publish_reminder" })}</p>
        </InfoBanner>

        <div className="flex justify-end mt-8">
          <div>
            <Button variant="secondary" onClick={onClose}>
              {$t({ id: "administrate.actions.ssot_documents.cancel" })}
            </Button>
          </div>
          <div className="ml-4">
            <Button variant="primary" onClick={handlePublish} loading={publishing}>
              {$t({ id: "administrate.actions.ssot_documents.publish" })}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
