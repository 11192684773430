export enum TimeUnits {
  Days = "days",
  Weeks = "weeks",
  Months = "months",
  Years = "years",
}

export type ValidationErrors = Record<string, string | undefined> | undefined

export enum DurationRangeKeys {
  Begin = "begin",
  End = "end",
}

export interface Duration {
  [DurationRangeKeys.Begin]?: string
  [DurationRangeKeys.End]?: string
  excludeEnd: boolean
}
