import { doFetch, Done, FetchMethod } from "./doFetch"

export type Fail = (error: string | Error) => void

export type CommentsFunction<R, T = {}> = (params: {
  req: T
  done: (response: R) => void
  fail: Fail
}) => void

export interface DeleteResponse {
  canDelete: boolean
  reason?: string
}

export interface LookupResponse {
  comments: {
    author: string
    createdAt: string
    content: string
    modifiedAt: string
    uid: string
  }[]
}

export interface CreateCommentsThreadRequest {
  content: string
  createdAt: string
}

export type CreateCommentsThreadFunction = CommentsFunction<
  { conversationUid: string },
  CreateCommentsThreadRequest
>
export type ReplyCommentFunction = CommentsFunction<
  { commentUid: string },
  {
    content: string
    createdAt: string
    conversationUid: string
  }
>
export type EditCommentFunction = CommentsFunction<
  {
    canEdit: boolean
    reason?: string
  },
  {
    conversationUid: string
    commentUid: string
    content: string
    modifiedAt: string
  }
>
export type DeleteCommentsThreadFunction = CommentsFunction<
  DeleteResponse,
  { conversationUid: string }
>
export type DeleteAllCommentThreadsFunction = CommentsFunction<DeleteResponse>
export type DeleteCommentFunction = CommentsFunction<
  DeleteResponse,
  { conversationUid: string; commentUid: string }
>
export type LookupCommentsFunction = CommentsFunction<LookupResponse, { conversationUid: string }>
export type ResolveCommentsFunction = CommentsFunction<
  {
    canResolve: boolean
    reason?: string
  },
  {
    conversationUid: string
  }
>

interface UseCommentsAPIProps {
  ssotSectionId: number
}

interface DoDeleteParams {
  endpoint: string
  done?: Done
  fail?: (e: Error) => void
}

const doDelete = ({ endpoint, done, fail }: DoDeleteParams) =>
  doFetch({
    endpoint,
    method: FetchMethod.DELETE,
    onResponse: response => {
      if (response.ok) {
        done?.({ canDelete: true })
      } else if (response.status === 404) {
        // 404 should only happen if another user deletes the same comment while the current use is on the page, in which case we should just act like a normal deletion.
        done?.({ canDelete: true })
      } else if ([403, 422].includes(response.status)) {
        done?.({ canDelete: false })
      } else {
        fail?.(new Error("Something has gone wrong..."))
      }
    },
  })

export const useCommentsAPI = ({ ssotSectionId }: UseCommentsAPIProps) => {
  const createCommentsThread: CreateCommentsThreadFunction = ({ req, done, fail }) =>
    doFetch({
      endpoint: "conversations",
      method: FetchMethod.POST,
      body: { commentPayload: req, ssotSectionId },
      errorMessage: "Failed to create comment",
      parseResponseField: "conversationUid",
      done,
      fail,
    })

  const replyComment: ReplyCommentFunction = ({ req, done, fail }) => {
    doFetch({
      endpoint: `conversations/${req.conversationUid}/reply`,
      method: FetchMethod.POST,
      body: { commentPayload: req, ssotSectionId },
      errorMessage: "Failed to reply to comment",
      parseResponseField: "commentUid",
      done,
      fail,
    })
  }

  const editComment: EditCommentFunction = ({ req, done, fail }) => {
    doFetch({
      endpoint: `comments/${req.commentUid}`,
      method: FetchMethod.PUT,
      body: { commentPayload: req, ssotSectionId },
      errorMessage: "Failed to edit comment",
      parseResponseField: "canEdit",
      done,
      fail,
    })
  }

  const deleteCommentsThread: DeleteCommentsThreadFunction = ({ req, done, fail }) => {
    const { conversationUid } = req
    doDelete({
      endpoint: `conversations/${conversationUid}`,
      done: response => done(response as unknown as DeleteResponse),
      fail,
    })
  }

  const deleteAllCommentThreads: DeleteAllCommentThreadsFunction = ({ done, fail }) => {
    doDelete({
      endpoint: `conversations/destroy_all?ssotSectionId=${ssotSectionId}`,
      done: response => done(response as unknown as DeleteResponse),
      fail,
    })
  }

  const deleteComment: DeleteCommentFunction = ({ req: { commentUid }, done, fail }) => {
    doDelete({
      endpoint: `comments/${commentUid}`,
      done: response => done(response as unknown as DeleteResponse),
      fail,
    })
  }

  const lookupComments: LookupCommentsFunction = async ({ req, done, fail }) => {
    await doFetch({
      endpoint: `conversations/${req.conversationUid}`,
      method: FetchMethod.GET,
      onResponse: async response => {
        if (!response.ok) {
          fail(new Error("Failed to get conversation"))
        }
        const jsonResponse = await response.json()
        done(jsonResponse)
      },
    })
  }

  const resolveComments: ResolveCommentsFunction = ({ req, done, fail }) => {
    const conversationPayload = { resolved: true }

    doFetch({
      endpoint: `conversations/${req.conversationUid}`,
      method: FetchMethod.PUT,
      body: { conversationPayload, ssotSectionId },
      errorMessage: "Failed to resolve conversation",
      parseResponseField: "canResolve",
      done,
      fail,
    })
  }

  return {
    createCommentsThread,
    replyComment,
    editComment,
    deleteCommentsThread,
    deleteAllCommentThreads,
    deleteComment,
    lookupComments,
    resolveComments,
  }
}
