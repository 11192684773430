import React, { KeyboardEvent, forwardRef, useCallback, useEffect } from "react"
import { TextInput } from "../TextInput"
import { LoadingSpinner } from "../LoadingSpinner"
import { useMultiSelectContext } from "./MultiSelectContext"
import { MultiSelectInputProps } from "./types"
const magnifyingGlass = require("./assets/magnifying-glass.svg")

/**
 * When user does not provide their own search input, this default one which supports string matching will be shown
 */
export const DefaultMultiSelectInput = () => {
  const {
    defaultSearchInputValue: search,
    setDefaultSearchInputValue: setSearch,
    getSearchInputProps,
  } = useMultiSelectContext()
  useEffect(() => {
    return () => {
      // clear on unmount
      setSearch?.("")
    }
  }, [setSearch])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      setSearch?.(value)
    },
    [setSearch],
  )

  return <MultiSelectInput value={search} onChange={handleChange} {...getSearchInputProps()} />
}

export const MultiSelectInput = forwardRef<HTMLInputElement, MultiSelectInputProps>(
  ({ onKeyDown, onChange, value, isLoading, placeholder = "Search", ...rest }, ref) => {
    const {
      listNodeRef,
      activeIndex,
      onChange: onMultiSelectValueChange,
      value: multiSelectInputValue,
      removeValue,
    } = useMultiSelectContext()

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
          event.preventDefault()
          const newValue = listNodeRef?.current[Number(activeIndex)].dataset.value as string
          onMultiSelectValueChange?.(
            multiSelectInputValue?.includes(newValue)
              ? removeValue?.(newValue)
              : [...(multiSelectInputValue || []), newValue],
          )
        }
        onKeyDown?.(event)
      },
      [
        activeIndex,
        listNodeRef,
        multiSelectInputValue,
        onKeyDown,
        onMultiSelectValueChange,
        removeValue,
      ],
    )

    return (
      <div className="sticky top-0 z-10 bg-white p-4">
        <TextInput
          ref={ref}
          {...rest}
          endAdornment={
            <div className="flex gap-2.5">
              {isLoading && <LoadingSpinner size="sm" />}
              <img src={magnifyingGlass} alt="Search" />
            </div>
          }
          onChange={onChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          size="sm"
          value={value}
        />
      </div>
    )
  },
)

MultiSelectInput.displayName = "MultiSelect.Input"
