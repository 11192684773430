import { DecisionRule } from "@/types/DecisionTable"
import { ICountry, countries } from "countries-list"

export const orderDecisionRowsByCountryAndSubdivision = (decisionRows: DecisionRule[]) =>
  decisionRows.sort((a, b) => {
    const [, countryA] = Object.entries(countries).find(entry => {
      const [countryCode] = entry
      return countryCode === a.countryCode
    }) as [string, ICountry]
    const [, countryB] = Object.entries(countries).find(entry => {
      const [countryCode] = entry
      return countryCode === b.countryCode
    }) as [string, ICountry]

    if (countryA.name === countryB.name) {
      if (b.subdivisionCode && !a.subdivisionCode) {
        return -1
      }
      if (a.subdivisionCode && !b.subdivisionCode) {
        return 1
      }
      if (a.subdivisionCode < b.subdivisionCode) {
        return -1
      }
      if (a.subdivisionCode > b.subdivisionCode) {
        return 1
      }
      return -1
    }

    if (countryA.name < countryB.name) {
      return -1
    }
    if (countryA.name > countryB.name) {
      return 1
    }
    return 0
  })
