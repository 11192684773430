import React from "react"
import { countries, getEmojiFlag, type TCountryCode } from "countries-list"

interface CountryCellProps {
  countryCode: string
}

export const CountryCell = ({ countryCode }: CountryCellProps) => {
  return (
    <span className="flex flex-row-reverse justify-end items-center">
      <span>{countries[countryCode as TCountryCode]?.name}</span>
      <span className="block mr-2 text-xl">{getEmojiFlag(countryCode as TCountryCode)}</span>
    </span>
  )
}
